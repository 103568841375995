import { ApolloClient } from '@apollo/client/core/ApolloClient';
import { ApolloLink } from '@apollo/client/core';
import { createHttpLink } from '@apollo/client/link/http/createHttpLink';
import { InMemoryCache } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { getToken } from './get-token';
import { useUserStore } from '../pinia/modules/user';
import { userCookies } from '@/shared/constants/cookies';
import { hobbiiSessionStorage } from './web-storage';

const recoveryLink = (onSuccess, onError) =>
  new RetryLink({
    delay: {
      initial: 0
    },
    attempts: {
      max: 2,
      retryIf: async (error) => {
        if (error.statusCode === 401) {
          const token = await getToken(true);
          if (token) {
            onSuccess(token);
          } else {
            onError();
          }
          return !!token;
        }
        return false;
      }
    }
  });

const enrichLink = () =>
  setContext(async (_, { headers }) => {
    const sessionToken = hobbiiSessionStorage.getItem(userCookies.C_ID);

    const authTokenHeader = sessionToken
      ? { Authorization: `Bearer ${sessionToken}` }
      : {};

    return {
      headers: {
        ...headers,
        ...authTokenHeader,
        'Content-Type': 'application/json'
      }
    };
  });

const httpLink = (graphqlHost) =>
  createHttpLink({
    uri: `${graphqlHost}`
  });

const cache = new InMemoryCache({
  typePolicies: {
    CommunityQueries: {
      keyFields: ['__typename']
    }
  }
});

const apolloClientInit = (options) => {
  const userStore = useUserStore();

  const setReceivedCustomer = (token) => {
    const userStore = useUserStore();
    userStore.setCustomer(token);
  };

  setReceivedCustomer();

  return new ApolloClient({
    link: ApolloLink.from([
      recoveryLink(setReceivedCustomer, userStore.cleanCustomer),
      enrichLink(),
      httpLink(options.graphqlHost)
    ]),
    cache
  });
};

export default apolloClientInit;
