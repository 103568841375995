import registerHBModal from '../shared/custom-elements/hb-modal';
import { initHobbiiStarter } from '../shared/instances/hobbii-starter';
import { initNotifications } from '../shared/instances/notifications';
import { initTocVerification } from '../shared/instances/toc-verification';
import { initLoginModal } from '../shared/instances/login-modal';
import { initStickyHeader } from '../shared/instances/sticky-header';
import { initCountrySwitcher } from '../shared/instances/country-switcher';
import { initAutoComplete } from '../shared/instances/autocomplete';
import { initNewsletterSignupForm } from '../shared/instances/newsletter-signup-form';
import { initGtmTracking } from '../shared/instances/gtm-tracking';

registerHBModal();
initHobbiiStarter();
initNotifications();
initTocVerification();
initLoginModal();
initAutoComplete();
initNewsletterSignupForm();
initStickyHeader();
initCountrySwitcher();
initGtmTracking();
