/**
 If the user's browser has the "Block third-party cookies and site data"
 checkbox set in Content Settings, storage access will be denied resulting on
 any attempt of accessing it throwing a reference error.
 */

const isSessionStorageAvailable = () => {
  try {
    if (typeof sessionStorage === 'undefined') return false;
    sessionStorage.setItem('__test', 'test');
    sessionStorage.removeItem('__test');
    return true;
  } catch (error) {
    return false;
  }
};
// TODO un-comment this when merged into Community
// const isLocalStorageAvailable = () => {
// try {
//   if (typeof localStorage === 'undefined') return false;
//     localStorage.setItem('__test', 'test');
//     localStorage.removeItem('__test');
//     return true;
//   } catch {
//     return false;
//   }
// };

export const hobbiiSessionStorage = {
  setItem: (keyName: string, keyValue: string) =>
    isSessionStorageAvailable() && sessionStorage.setItem(keyName, keyValue),
  getItem: (keyName: string) =>
    isSessionStorageAvailable() ? sessionStorage.getItem(keyName) : null,
  removeItem(keyName: string) {
    isSessionStorageAvailable() && sessionStorage.removeItem(keyName);
  }
};

// TODO un-comment this when merged into Community
// export const hobbiiLocalStorage = {
//   setItem: (keyName: string, keyValue: string) =>
//     isLocalStorageAvailable() && localStorage.setItem(keyName, keyValue),
//   getItem: (keyName: string) =>
//     isLocalStorageAvailable() ? localStorage.getItem(keyName) : null,
//   removeItem(keyName: string) {
//     isLocalStorageAvailable() && localStorage.removeItem(keyName);
//   }
// };
